import { createStore as reduxCreateStore, compose } from 'redux';

import actions from './actions';

const headerHeight = 84;

const setState = key => (state, payload) =>
  key ? { ...state, [key]: payload } : payload;

const setViewHeightHandler = state => {
  const introHeight =
    document.getElementById('mainIntro').clientHeight - headerHeight;
  const skillsHeight =
    document.getElementById('skillsets').clientHeight + introHeight + 50;
  const historyHeight = document.body.scrollHeight - document.body.offsetHeight;
  return {
    ...state,
    introH: introHeight,
    skillsH: skillsHeight,
    historyH: historyHeight,
  };
};

const messageVisiableHandler = state => {
  let messageVisible;
  if (localStorage.getItem('hideMessage') === null && !state.visible) {
    messageVisible = false;
  } else if (localStorage.getItem('hideMessage') !== null) {
    messageVisible = false;
  } else {
    messageVisible = true;
  }
  return { ...state, visible: messageVisible };
};

const reducer = (state, action) => {
  const { type, payload } = action;

  const reducerList = {
    [actions.SET_VIEW_HEIGHT]: setViewHeightHandler,
    [actions.CHECK_MESSAGE_VISIBLE]: messageVisiableHandler,
    [actions.SET_MESSAGE_VISIBLE]: setState('visible'),
  }[type];

  return reducerList ? reducerList(state, payload) : state;
};

const initialState = {
  introH: 0,
  skillsH: 0,
  historyH: 0,
  visible: true,
};

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const createStore = () =>
  reduxCreateStore(reducer, initialState, composeEnhancers());
export default createStore;
